import React from 'react';
import { Link, Asset } from '@hz-design-system/web-ui';
import { trackShareList } from '../SocialShareUtils';

const handleWindowPopup = (url: string, e: Event) => {
  e.preventDefault();
  const popupWindow = window.open('', 'preview', 'scrollbars=yes,resizable=yes,top=200,left=300,width=600,height=400');
  if (popupWindow) {
    popupWindow.opener = null;
    popupWindow.location = url;
  }
  return false;
};

const handleClick = (url: string, type: string, openInPopup: boolean, e: Event) => {
  trackShareList(type);
  if (openInPopup) {
    handleWindowPopup(url, e);
  }
};

const SocialLink = ({ url, type, openInPopup = true }: { url: string; type: string; openInPopup: boolean }) => {
  if (!url) {
    return null;
  }

  return (
    <Link link={url} onClick={(e: Event) => handleClick(url, type, openInPopup, e)} rel="nofollow">
      <Asset.Square name={`social-share-${type}`} />
    </Link>
  );
};

export default SocialLink;
