const PHONE_NUMBER_PRO_FORMATTED = '088 0082626';
const COMPANY_NAME_WEBSITE_MAX_LENGTH = 255;
const KVK_MAX_LENGTH = 8;
const CONTACT_FIELDS_MAX_LENGTH = 40;
const NAMES_MIN_LENGTH = 2;
const INPUT_MIN_LENGTH = 1;
const INPUT_MAX_LENGTH = 100;
const INPUT_USER_NAME_MAX_LENGTH = 30;
const LOCALE_NL = 'NL';
const TOAST_AUTOCLOSE_TIMER = 5000;
const LOCALE_EN_US = 'en-US';
const STORE = 'store';
const INVALID_PARAMETER_ERROR = 'INVALID_PARAMETER';
const EMAIL_FIELD = 'email';

const ERROR_REASON = {
  verificationRequired: 'verificationRequired',
  verificationRequestsBlocked: 'VERIFICATION_REQUESTS_BLOCKED',
};

const dropDownOptions = [
  {
    label: 'Nederland',
    value: 'Nederland',
  },
];

export {
  PHONE_NUMBER_PRO_FORMATTED,
  COMPANY_NAME_WEBSITE_MAX_LENGTH,
  KVK_MAX_LENGTH,
  CONTACT_FIELDS_MAX_LENGTH,
  NAMES_MIN_LENGTH,
  LOCALE_NL,
  TOAST_AUTOCLOSE_TIMER,
  INPUT_MIN_LENGTH,
  INPUT_MAX_LENGTH,
  dropDownOptions,
  LOCALE_EN_US,
  STORE,
  INPUT_USER_NAME_MAX_LENGTH,
  INVALID_PARAMETER_ERROR,
  EMAIL_FIELD,
  ERROR_REASON,
};
