export const types = {
  WHATSAPP: 'whatsapp',
  MESSENGER: 'messenger',
  FACEBOOK: 'facebook',
  TWITTER: 'twitter',
  EMAIL: 'email',
};

export const eventActions = {
  SHARE_BEGIN: 'ShareSellerProfileBegin',
};

export const eventLabelsByType = {
  [types.WHATSAPP]: 'Whatsapp',
  [types.MESSENGER]: 'Facebook-Messenger',
  [types.FACEBOOK]: 'Facebook',
  [types.TWITTER]: 'Twitter',
  [types.EMAIL]: 'Email',
};

export const utmSourcesByType = {
  [types.WHATSAPP]: 'whatsapp',
  [types.MESSENGER]: 'facebook-messenger',
  [types.FACEBOOK]: 'facebook',
  [types.TWITTER]: 'twitter_large',
  [types.EMAIL]: 'email',
};
