// Runtime webpack config
import '../../other/WebpackConfig';

// i18n
import i18n from '../../i18n/nl-NL';

import Profile from './Profile';

// Tenant-specific styles
import '../../../scss/site/global/mp-hz-design.scss';

Profile(i18n);
