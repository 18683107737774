const trackEvent = ({ eventAction = '', eventLabel = '' }) => {
  if (eventAction || eventLabel) {
    const event = { eventAction, eventLabel };
    if (window.ecGa && window.ga) {
      window.ecGa('trackEvent', event);
    }
  }
};

export default trackEvent;
