import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import { Icon, Text, Link } from '@hz-design-system/web-ui';

import { trackShareList } from '../SocialShareUtils';
import Classes from '../SocialShare.scss';

const CopyLink = ({ url }: { url: string }) => {
  const { t } = useI18nContext();
  const [isCopying, setIsCopying] = useState(false);

  const onCopy = () => {
    trackShareList('copy-btn');
    setIsCopying(true);
    const ANIMATION_DURATION = 2000;
    setTimeout(() => setIsCopying(false), ANIMATION_DURATION);
  };

  const animationClasses = [Classes.copyLinkAnimation];
  if (isCopying) {
    animationClasses.push(Classes.copyLinkAnimationAnimating);
  }

  return (
    <Text.Small as="p" className={Classes.copyLink}>
      <span>{t('socialShare.or')}</span>
      <CopyToClipboard text={url} onCopy={onCopy}>
        <Link>{t('socialShare.copyLink')}</Link>
      </CopyToClipboard>
      <span className={animationClasses.join(' ')}>
        <Icon name="checkmark--success" />
      </span>
    </Text.Small>
  );
};
export default CopyLink;
