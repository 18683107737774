import React from 'react';
import { Icon } from '@hz-design-system/web-ui';

import Classes from './UniqueSellingPoint.scss';

const UniqueSellingPoint = ({ text }: { text: string }) => (
  <div className={Classes.root}>
    <Icon name="checkmark-circled" color="success" size="m" className={Classes.icon} />
    <div className={Classes.text}>{text}</div>
  </div>
);

export default UniqueSellingPoint;
