import React from 'react';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import { BasicModal, StarRating } from '@hz-design-system/web-ui';
import average from '../../utils/average';

import SubScores from './SubScores/SubScores';
import LastReviewsList from './LastReviewsList/LastReviewsList';
import * as iframeUtils from '../../utils/iframeUtils';

import Classes from './ReviewsDialog.scss';

type CarDealerReviewsProps = {
  carDealerReviews: {
    avgSubscores: any;
    lastReviews: any;
    avgOverallScore: string;
    provider: string;
  };
  sellerProfile: {
    title: string;
    periodSinceRegistrationDate: string;
  };
};
const CarDealerReviews = ({ carDealerReviews, sellerProfile }: CarDealerReviewsProps) => {
  const { avgSubscores, lastReviews, avgOverallScore, provider } = carDealerReviews;

  const { title, periodSinceRegistrationDate } = sellerProfile;

  return (
    <>
      <div className={Classes.header}>
        <div className={Classes.heading}>
          <h2>{title}</h2>
          {avgOverallScore && (
            <>
              <div className={Classes.score}>{avgOverallScore}</div>
              {/* @ts-ignore TODO check size */}
              <StarRating size="s" rating={average(avgOverallScore)} />
            </>
          )}
        </div>
        <p>{periodSinceRegistrationDate}</p>
      </div>
      {Boolean(avgSubscores) && Boolean(avgSubscores.length) && <SubScores subScores={avgSubscores} />}
      <div className={Classes.content}>
        {Boolean(lastReviews) && <LastReviewsList lastReviews={lastReviews} />}
        {provider && <p>{provider}</p>}
      </div>
    </>
  );
};

type ReviewsDialogProps = {
  closeReviewsPopup: () => void;
  userReviews: any;
  carDealerReviews: any;
  sellerProfile: any;
  sellerId: string;
};
const ReviewsDialog = ({
  closeReviewsPopup,
  carDealerReviews,
  userReviews,
  sellerProfile,
  sellerId,
}: ReviewsDialogProps) => {
  const i18n = useI18nContext();
  return (
    <BasicModal size="l" title={i18n.t('reviewsDialog.title')} onClose={closeReviewsPopup} className={Classes.popup}>
      {carDealerReviews && <CarDealerReviews carDealerReviews={carDealerReviews} sellerProfile={sellerProfile} />}
      {userReviews && !carDealerReviews && (
        <iframe
          className={Classes.reviewsIframe}
          title="user-reviews-dialog"
          src={iframeUtils.buildIFrameSrc(sellerId)}
          width={iframeUtils.calculateIFrameWidth()}
          height={iframeUtils.calculateIFrameHeight()}
        />
      )}
    </BasicModal>
  );
};

export default ReviewsDialog;
