import React from 'react';

const defaultValues: {
  staticBaseUrl: string;
  xsrfToken: string;
  baseUrl: string;
  hostname: string;
  properties: {
    facebookAppId: string;
    twitterHandle: string;
  };
  tenant: string;
  links: Record<string, string>;
  social: {
    googleSdkUrl: string;
    googleClientID: string;
  };
  threatMetrix: Record<string, any>;
} = {
  staticBaseUrl: '',
  xsrfToken: '',
  baseUrl: '',
  hostname: '',
  properties: {
    facebookAppId: '',
    twitterHandle: '',
  },
  tenant: '',
  links: {},
  social: { googleSdkUrl: '', googleClientID: '' },
  threatMetrix: {},
};

const EnvironmentContext = React.createContext(defaultValues);

export const EnvironmentProvider = EnvironmentContext.Provider;
export const EnvironmentConsumer = EnvironmentContext.Consumer;

export default EnvironmentContext;
