import React, { useContext } from 'react';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import { Card, Icon } from '@hz-design-system/web-ui';
import isURL from 'validator/lib/isURL';
import filterXSS from 'xss';
import VideoPlay from '../VideoPlay/VideoPlay';
import SocialShare from '../SocialShare';
import EnvironmentContext from '../../contexts/EnvironmentContext';

import Classes from './ProfileBody.scss';
import UniqueSellingPoints from './UniqueSellingPoints';

type ProfileBodyProps = {
  description: string;
  uniqueSellingPoints: string[];
  isFinanceAvailable: boolean;
  companyVideoUrl: string;
  awards: string;
  brands: { id: string; name: string; url: string[] }[];
  sellerId: string;
  sellerTitle: string;
};

const ProfileBody = ({
  description,
  uniqueSellingPoints,
  isFinanceAvailable,
  companyVideoUrl,
  awards,
  brands,
  sellerId,
  sellerTitle,
}: ProfileBodyProps) => {
  const i18n = useI18nContext();
  const { hostname } = useContext(EnvironmentContext);

  return (
    <Card kind="rounded">
      <div className={Classes.card}>
        {!!uniqueSellingPoints.length && (
          <div className={Classes.uspsWrap}>
            <UniqueSellingPoints uniqueSellingPoints={uniqueSellingPoints} />
          </div>
        )}
        <h3 className={Classes.heading}>{i18n.t('sellerHeader.aboutUs')}</h3>
        {description && (
          <div dangerouslySetInnerHTML={{ __html: filterXSS(description) }} /> // eslint-disable-line react/no-danger
        )}
        {isFinanceAvailable && (
          <div className={Classes.info}>
            <Icon name="euros" className={Classes['infoWrap-icon']} />
            <strong>{i18n.t('sellerInfo.finance')}</strong>
          </div>
        )}
        {isURL(companyVideoUrl) && (
          <div className={Classes.videoWrap}>
            <VideoPlay
              src={companyVideoUrl}
              className={Classes.videoPlay}
              // @ts-ignore FIXME: Add missing prop controls to VideoPlay component
              controls
            />
          </div>
        )}
        {awards && (
          <div className={Classes.awards}>
            <h3 className={Classes.heading}>{i18n.t('sellerAwards.title')}</h3>
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: filterXSS(awards) }} />
          </div>
        )}
        {brands && !!brands.length && (
          <div className={Classes.brands}>
            <h3 className={Classes.heading}>{i18n.t('sellerBrands.title')}</h3>
            <div className={Classes.brandsImages}>
              {brands.map((brand) => (
                <img
                  key={brand.id}
                  alt={brand.name}
                  title={brand.name}
                  className={Classes.brandImage}
                  src={brand.url[0]}
                  srcSet={`${brand.url?.[0]} 1x, ${brand.url?.[1]} 2x`}
                />
              ))}
            </div>
          </div>
        )}
      </div>
      <SocialShare linkToShare={`https://${hostname}/smb-profile/profile/${sellerId}`} message={sellerTitle} />
    </Card>
  );
};

export default ProfileBody;
