import React, { useContext } from 'react';

import EnvironmentContext from '../../../contexts/EnvironmentContext';
import getStaticImageUrl from '../../../utils/getStaticImageUrl';

import DefaultSOI from '../../../../images/seller-profile/soi_profile.jpg';
import Classes from './TopSection.scss';

type TopSectionProps = {
  title: string;
  hasSellerProfile?: boolean;
  mission?: string;
  backdropUrl?: string;
  photo?: string;
};

const TopSectionWithImagery = ({ title, mission, backdropUrl, photo }: TopSectionProps) => {
  const backdropStyles = backdropUrl ? { backgroundImage: `url(${backdropUrl || ''})` } : {};
  const { staticBaseUrl } = useContext(EnvironmentContext);

  return (
    <div className={`${Classes.backdrop} hz-Card-block hz-Card-block--padded-no`}>
      <div className={Classes['backdrop--stretchy']} style={backdropStyles}>
        <div className={Classes.image}>
          <img src={photo || getStaticImageUrl(staticBaseUrl, DefaultSOI)} alt={title} title={mission} />
        </div>
        <div className={Classes.titleWrap}>
          <div className={Classes['titleWrap-Name']}>{title}</div>
          {mission && <div className={Classes['titleWrap-Slogan']}>{mission}</div>}
        </div>
      </div>
    </div>
  );
};

const TopSectionWithNoImagery = ({ title }: { title: string }) => (
  <div className={Classes.root}>
    <div className={Classes.titleWrap}>
      <div className={Classes['titleWrap-Name']}>{title}</div>
    </div>
  </div>
);

const TopSection = ({ hasSellerProfile, ...props }: TopSectionProps) =>
  hasSellerProfile ? <TopSectionWithImagery {...props} /> : <TopSectionWithNoImagery {...props} />;

export default TopSection;
