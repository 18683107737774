import React from 'react';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import { StarRating, Card } from '@hz-design-system/web-ui';
import getNumberOrZero from '../../../utils/getNumberOrZero';
import SaveSellerControl from '../SaveSellerControl/SaveSellerControl';
import Classes from './Info.scss';
import { PrimaryButton, ButtonIcon } from '@hz-design-system/web-ui';

type InfoProps = {
  sellerId: number;
  isUserViewingOwnProfile: boolean;
  editProfileUrl: string;
  isSellerSaved: boolean;
  isSaveSellerControlVisible: boolean;
  periodSinceRegistrationDate: string;
  onClickSaveControl: () => void;
  saveControlLoading?: boolean;
  userReviews?: {
    summary?: {
      averageScore: number;
      numberOfReviews: number;
    };
  };
  carDealerReviews?: {
    avgOverallScore: number;
    numberOfReviews: number;
  };
  onClickReviewsControl?: () => void;
  location?: string;
  hasSellerProfile?: boolean;
};

const Info = ({
  sellerId,
  isUserViewingOwnProfile,
  editProfileUrl,
  isSellerSaved,
  isSaveSellerControlVisible,
  periodSinceRegistrationDate,
  onClickSaveControl,
  saveControlLoading = false,
  userReviews,
  carDealerReviews,
  onClickReviewsControl,
  hasSellerProfile,
}: InfoProps) => {
  const i18n = useI18nContext();

  let averageScore = 0;
  let numberOfReviews = 0;

  if (carDealerReviews) {
    averageScore = carDealerReviews.avgOverallScore ? Number((carDealerReviews.avgOverallScore / 2).toPrecision(2)) : 0;
    numberOfReviews = carDealerReviews.numberOfReviews;
  } else if (userReviews) {
    averageScore = userReviews.summary?.averageScore ?? 0;
    numberOfReviews = userReviews.summary?.numberOfReviews ?? 0;
  }

  const reviewsLabel =
    numberOfReviews === 1
      ? i18n.t('sellerHeader.review', { count: numberOfReviews })
      : i18n.t('sellerHeader.review_plural', { count: numberOfReviews });

  const handleClickButton = (evt: any) => {
    evt.preventDefault();
    onClickSaveControl();
  };

  const handleClickReviews = (evt: any) => {
    evt.preventDefault();

    if (onClickReviewsControl) {
      onClickReviewsControl();
    }
  };

  const renderSaveSellerControl = () =>
    isSaveSellerControlVisible && (
      <SaveSellerControl
        sellerId={sellerId}
        isSellerSaved={isSellerSaved}
        onClick={handleClickButton}
        loading={saveControlLoading}
      />
    );

  const textJoinedSince = i18n.t('sellerHeader.joinedSince', { value: periodSinceRegistrationDate });

  const redirectToEditSellerProfile = () => window.location.assign(editProfileUrl);

  return (
    <Card className={`${Classes.root} ${hasSellerProfile ? '' : Classes.noProfile}`}>
      <div className={Classes.infoWrap}>
        {!!averageScore && (
          <div onClick={handleClickReviews} role="button">
            <StarRating label={`(${numberOfReviews}) ${reviewsLabel}`} rating={getNumberOrZero(averageScore)} />
          </div>
        )}
        <div className={Classes.text}>{textJoinedSince}</div>
      </div>

      {isUserViewingOwnProfile && (
        <div className={Classes.buttonWrap}>
          <PrimaryButton onClick={redirectToEditSellerProfile}>
            <ButtonIcon name="edit" isStartIcon={true} />
            {i18n.t('sellerHeader.buttonEditProfile')}
          </PrimaryButton>
        </div>
      )}
      {!isUserViewingOwnProfile && <div className={Classes.buttonWrap}>{renderSaveSellerControl()}</div>}
    </Card>
  );
};

export default Info;
