import React, { useState } from 'react';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import { ErrorInpage, SuccessInpage, UtilsBlock, PageElement } from '@hz-design-system/web-ui';
import requestStatus from '../../constants/requestStatus';
import ProfileHero from '../../components/ProfileHero/ProfileHero';
import ProfileBody from '../../components/ProfileBody/ProfileBody';
import ProfileSidebar from '../../components/ProfileSidebar/ProfileSidebar';
import InactiveSellerProfileBody from '../../components/ProfileBody/InactiveSellerProfileBody';
import saveSellerRequest from '../../services/saveSeller';
import deleteSellerRequest from '../../services/deleteSeller';
import ReviewsDialog from '../../components/ReviewsDialog/ReviewsDialog';
import { EnvironmentProvider } from '../../contexts/EnvironmentContext';

import './SellerProfile.scss';

const SellerProfileApp = ({ appState, environment }) => {
  const { isUserViewingOwnProfile, editProfileUrl, profile, loggedInUser, notifications } = appState;
  const { xsrfToken } = environment;
  const i18n = useI18nContext();

  const {
    id,
    hasSellerProfile,
    description,
    dsaFlags,
    title,
    location,
    contactInformation: { city, postCode, street, email, phoneNumber },
    companyRegistrationData,
    businessHours,
    logo,
    isSavedSeller,
    hasGeolocation,
    sellerGeolocation,
    uniqueSellingPoints,
    websiteUrl,
    isFinanceAvailable,
    companyVideoUrl,
    carDealerReviews,
    userReviews,
    awards,
    brands,
    salesRepresentatives,
  } = profile;

  const [saveSellerRequestStatus, setSellerRequestStatus] = useState(requestStatus.initial);
  const [isSellerSaved, setSellerAsSaved] = useState(isSavedSeller);
  const [notification, setNotification] = useState('');
  const [isReviewsPopupOpen, setReviewsPopupOpen] = useState(false);

  const translatedText = {
    saveSellerSuccessMessage: i18n.t('notifications.saveSellerSuccess', { url: '/mymp/favoriete-verkopers.html' }),
    deleteSellerSuccessMessage: i18n.t('notifications.deleteSellerSuccess', { url: '/mymp/favoriete-verkopers.html' }),
    genericErrorMessage: i18n.t('notifications.genericError'),
  };

  const renderNotifications = () => {
    let combinedNotifications = [];

    // Notification from saveSeller/deleteSeller action
    if (notification) {
      combinedNotifications = combinedNotifications.concat({
        message: notification,
        isSuccess: Boolean(saveSellerRequestStatus === requestStatus.success),
        isError: Boolean(saveSellerRequestStatus === requestStatus.failure),
      });
    }

    combinedNotifications = combinedNotifications.concat(
      notifications.map((notif) => ({
        message: notif.message,
        isSuccess: notif.isSuccess,
        isError: !notif.isSuccess,
      })),
    );

    if (!combinedNotifications.length) {
      return null;
    }

    const [displayableNotification] = combinedNotifications;

    return (
      <PageElement kind="full-width">
        <UtilsBlock marginTop="s" marginBottom="s">
          {displayableNotification.isSuccess && (
            <SuccessInpage
              icon="checkmark-circle"
              description={<span dangerouslySetInnerHTML={{ __html: displayableNotification.message }} />} // eslint-disable-line react/no-danger
            />
          )}
          {displayableNotification.isError && (
            <ErrorInpage
              description={<span dangerouslySetInnerHTML={{ __html: displayableNotification.message }} />} // eslint-disable-line react/no-danger
              icon="alert"
            />
          )}
        </UtilsBlock>
      </PageElement>
    );
  };

  const saveSeller = async () => {
    try {
      await saveSellerRequest({ xsrfToken, sellerId: id });
      setSellerRequestStatus(requestStatus.success);
      setSellerAsSaved(true);
      setNotification(translatedText.saveSellerSuccessMessage);
    } catch (err) {
      setSellerRequestStatus(requestStatus.failure);
      setNotification(translatedText.genericErrorMessage);
    } finally {
      window.scroll(0, 0);
    }
  };

  const deleteSeller = async () => {
    try {
      await deleteSellerRequest({ xsrfToken, sellerId: id });
      setSellerRequestStatus(requestStatus.success);
      setSellerAsSaved(false);
      setNotification(translatedText.deleteSellerSuccessMessage);
    } catch (err) {
      setSellerRequestStatus(requestStatus.failure);
      setNotification(translatedText.genericErrorMessage);
    } finally {
      window.scroll(0, 0);
    }
  };

  const redirectToLogInScreen = () =>
    window.location.assign(`/account/login.html?target=${encodeURIComponent(window.location.href)}`);

  const onClickSaveControl = () => {
    if (!loggedInUser) {
      return redirectToLogInScreen();
    }
    const toggleSaveSeller = isSellerSaved ? deleteSeller : saveSeller;
    return toggleSaveSeller();
  };

  const closeReviewsPopup = () => setReviewsPopupOpen(false);

  const openReviewsPopup = () => setReviewsPopupOpen(true);

  return (
    <EnvironmentProvider value={environment}>
      <div>
        {renderNotifications()}
        {isReviewsPopupOpen && (carDealerReviews || userReviews) && (
          <ReviewsDialog
            closeReviewsPopup={closeReviewsPopup}
            carDealerReviews={carDealerReviews}
            userReviews={userReviews}
            sellerProfile={profile}
            sellerId={id}
          />
        )}
        <UtilsBlock marginBottom="s">
          <ProfileHero
            isUserViewingOwnProfile={isUserViewingOwnProfile}
            sellerProfile={profile}
            editProfileUrl={editProfileUrl}
            loggedInUserId={loggedInUser && loggedInUser.id}
            onClickSaveControl={onClickSaveControl}
            isSellerSaved={isSellerSaved}
            saveControlLoading={false}
            carDealerReviews={carDealerReviews}
            userReviews={userReviews}
            onClickReviewsControl={openReviewsPopup}
          />
        </UtilsBlock>
        <div className="contentWrap">
          <div className="leftCol">
            {hasSellerProfile ? (
              <ProfileBody
                sellerId={id}
                sellerTitle={title}
                description={description}
                isFinanceAvailable={isFinanceAvailable}
                uniqueSellingPoints={uniqueSellingPoints}
                companyVideoUrl={companyVideoUrl}
                awards={awards}
                brands={brands}
              />
            ) : (
              <InactiveSellerProfileBody sellerProfile={profile} />
            )}
          </div>
          <aside className="rightCol">
            <ProfileSidebar
              dsaFlags={dsaFlags}
              sellerId={id}
              sellerTitle={title}
              location={location}
              contactInformation={{ street, postCode, city, email, phoneNumber }}
              companyRegistrationData={companyRegistrationData}
              logoImage={logo}
              hasGeolocation={hasGeolocation}
              sellerGeolocation={sellerGeolocation}
              websiteUrl={websiteUrl}
              carDealerReviews={carDealerReviews}
              onClickReviewsControl={openReviewsPopup}
              salesRepresentatives={salesRepresentatives}
              businessHours={businessHours}
            />
          </aside>
        </div>
      </div>
    </EnvironmentProvider>
  );
};

export default SellerProfileApp;
