import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import { StarRating } from '@hz-design-system/web-ui';
import React from 'react';
import average from '../../../utils/average';
import SubScores from '../SubScores/SubScores';

import Classes from './LastReviewsList.scss';

type LastReviewsListProps = {
  lastReviews: {
    name: string;
    place: string;
    overallScore: string;
    text: string;
    reason: string;
    date: string;
    subScores: {
      value: string;
      overallScore: string;
      name: string;
    }[];
  }[];
};
const LastReviewsList = ({ lastReviews }: LastReviewsListProps) => {
  const dateFormat = "D MMM. 'YY";
  const i18n = useI18nContext();

  return (
    <div className={Classes.list}>
      {lastReviews.map((review, index) => (
        <div key={`review-${index}`} className={Classes.item}>
          <div className={Classes.itemLeft}>
            <div className={Classes.title}>
              <h3>{review.name}</h3> ({review.place})
            </div>
            <div className={Classes.score}>
              {review.overallScore} <StarRating label="" rating={average(review.overallScore)} />
            </div>
            <div className={Classes.text}>{review.text}</div>
            <div className={Classes.reason}>
              <strong>{review.reason}</strong>
            </div>
          </div>
          <div className={Classes.itemRight}>
            {review.date && (
              <div className={Classes.date}>
                <strong>{i18n.date(review.date, dateFormat)}</strong>
              </div>
            )}
            {review.subScores && <SubScores subScores={review.subScores} />}
          </div>
        </div>
      ))}
    </div>
  );
};
export default LastReviewsList;
