import React from 'react';
import getSuitCSSClasses from '../../utils/getSuitCSSClasses';

type VideoPlayProps = {
  className?: string;
  src?: string;
};
const VideoPlay = ({ className, src, ...props }: VideoPlayProps = { className: '', src: '' }) => (
  <video className={getSuitCSSClasses({ name: 'VideoPlay', custom: className })} src={src} {...props} />
);

export default VideoPlay;
