import React from 'react';
import { Trans, useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import { Icon, PrimaryButton } from '@hz-design-system/web-ui';
import Classes from './InactiveSellerProfileBody.scss';

const InactiveSellerProfileBody = ({ sellerProfile }: { sellerProfile: { id: number; title: string } }) => {
  const { id: sellerId, title: sellerName } = sellerProfile;

  const soiUrl = `/verkopers/${sellerId}.html`;
  const i18n = useI18nContext();
  const btnLabel = i18n.t('inactive_profile.button_seller_own_listings');

  return (
    <div className={Classes.emptyState} data-state="empty">
      <div className={Classes.emptyStateBackground}>
        <div className={Classes.skyWrapper}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="82.812"
            height="20"
            className={Classes.sky}
            viewBox="0 0 82.812 20"
          >
            <circle fill="#D4D2CD" cx="55.945" cy="10" r="10" />
            <path
              className={`${Classes.cloud} ${Classes.cloud3}`}
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFF"
              d="M31.398 11.828l.007.014h5.185c1.182 0 2.182-.559 2.182-2.049 0-1.096-.725-1.606-1.064-1.798-.137-3.664-2.887-3.7-2.887-3.7-1.023 0-3.401.213-3.546 2.867 0 0-2.21.135-2.21 2.407 0 2.271 2.21 2.26 2.21 2.26l.123-.001z"
            />
            <path
              className={`${Classes.cloud} ${Classes.cloud1}`}
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFF"
              d="M76.131 8.703l-.007.014h-5.185c-1.182 0-2.182-.559-2.182-2.049 0-1.096.725-1.606 1.064-1.798.137-3.664 2.887-3.7 2.887-3.7 1.023 0 3.401.213 3.546 2.867 0 0 2.21.135 2.21 2.407 0 2.271-2.21 2.26-2.21 2.26l-.123-.001z"
            />
            <path
              className={`${Classes.cloud} ${Classes.cloud2}`}
              fill="#FFF"
              d="M48.457 13.079c0 2.236 2.115 2.419 3.541 2.419h5.83s4.855.406 4.855-3.037-3.064-3.443-3.064-3.443-.349-2.847-4.342-2.847c-5.223 0-4.709 4.671-4.709 4.671s-2.111 0-2.111 2.237z"
            />
            <path
              className={`${Classes.cloud} ${Classes.cloud4}`}
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFF"
              d="M5 14.963c0 3.093 2.076 4.252 4.527 4.252h19.388s2.03.111 2.03-1.924c0-2.034-2.03-2.062-2.03-2.062h-4.091s0-4.2-5.343-4.2C19.228 6.374 15.056 6 13.262 6s-6.12.755-5.707 5.029c.225 0-2.555.841-2.555 3.934z"
            />
          </svg>
        </div>
      </div>
      <div className={Classes.emptyStateContent}>
        <Icon name="profile" color="secondary" size="xxl" />
        <Trans i18nKey="inactive_profile.empty_state.title" tagName="h3" />
        <Trans
          i18nKey="inactive_profile.empty_state.content"
          sellerName={sellerName}
          tagName="p"
          className={Classes.emptyStateText}
        />
        <PrimaryButton as="a" type="button" href={soiUrl}>
          {btnLabel}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default InactiveSellerProfileBody;
