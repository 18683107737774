import React, { useContext } from 'react';
import GoogleMapReact from 'google-map-react';
import EnvironmentContext from '../../contexts/EnvironmentContext';

const Map = ({ latitude, longitude, center, zoom, options, standardMarkerProps }) => {
  const isDev = process.env.NODE_ENV === 'development';
  const {
    gmap: { apiKey: key },
  } = useContext(EnvironmentContext);
  const baseMapProps = {
    bootstrapURLKeys: {
      key: 'mock',
      ...(!isDev
        ? {
            key,
          }
        : {}),
    },
    defaultCenter: center,
    defaultZoom: zoom,
    options,
  };

  const { clickable, onClick } = standardMarkerProps;

  const renderMarkers = (map, maps) => {
    const marker = new maps.Marker({
      icon: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
      map,
      position: {
        lat: latitude,
        lng: longitude,
      },
    });

    if (clickable) {
      maps.event.addListener(marker, 'click', onClick);
    }

    return marker;
  };

  return (
    <GoogleMapReact
      {...baseMapProps}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
    />
  );
};

export default Map;
