import React, { useContext, useEffect, useState } from 'react';
import { Trans } from '@ecg-marktplaats/js-react-i18n';
import { Text } from '@hz-design-system/web-ui';

import { types } from '../../constants/SocialShare';
import EnvironmentContext from '../../contexts/EnvironmentContext';
import SocialLink from './atoms/SocialLink';
import CopyLink from './atoms/CopyLink';
import getIsMobile from '../../utils/isMobile';
import { buildUrlForType, addUtmParamsToLink } from './SocialShareUtils';

import Classes from './SocialShare.scss';

const shouldOpenInPopup = (type: string, isMobile: boolean) => {
  const { WHATSAPP, EMAIL } = types;
  const isMobileWhatsApp = type === WHATSAPP && isMobile;
  const isEmail = type === EMAIL;

  // mobile WhatsApp and Email should not open in popup;
  return !isMobileWhatsApp && !isEmail;
};

const SocialShare = ({ linkToShare, message }: { linkToShare: string; message: string }) => {
  const { hostname } = useContext(EnvironmentContext);

  const TYPES = Object.values(types);
  const {
    properties: { facebookAppId, twitterHandle },
  } = useContext(EnvironmentContext);
  const socialShareConfig = { facebookAppId, twitterHandle };
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(getIsMobile());
  }, []);

  return (
    <div className={Classes.root}>
      <div className={Classes.content}>
        <Text.Large>
          <Trans i18nKey="socialShare.title" tagName="span" />
        </Text.Large>
        <div className={Classes.links}>
          {TYPES.map((type) => (
            <SocialLink
              key={`social-share-${type}`}
              url={buildUrlForType({ type, linkToShare, message, socialShareConfig, isMobile, hostname })}
              type={type}
              openInPopup={shouldOpenInPopup(type, isMobile)}
            />
          ))}
          <CopyLink url={addUtmParamsToLink(linkToShare, 'copy-btn')} />
        </div>
      </div>
    </div>
  );
};

export default SocialShare;
