import { ButtonIcon, SecondaryButton } from '@hz-design-system/web-ui';
import React from 'react';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import Classes from './SaveSellerControl.scss';

type SaveSellerControlProps = {
  isSellerSaved: boolean;
  loading: boolean;
  onClick: (...args: any[]) => void;
  sellerId: number;
};
const SaveSellerControl = ({ onClick, sellerId, isSellerSaved, loading = false }: SaveSellerControlProps) => {
  const { t } = useI18nContext();

  return (
    <div className={Classes.root}>
      <div id="save-seller">
        <form id="save-seller-form" action="">
          <input name="sellerId" type="hidden" value={sellerId} />
          <SecondaryButton
            size="small"
            onClick={onClick}
            title={t('sellerHeader.buttonTitleFollowSeller')}
            disabled={loading}
          >
            <ButtonIcon name={isSellerSaved ? 'following' : 'follow'} isStartIcon={true} />
          </SecondaryButton>
        </form>
      </div>
    </div>
  );
};

export default SaveSellerControl;
