import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import React from 'react';
import UniqueSellingPoint from './UniqueSellingPoint';
import Classes from './ProfileBody.scss';

export default function UniqueSellingPoints({ uniqueSellingPoints = [] }: { uniqueSellingPoints: string[] }) {
  const { t } = useI18nContext();
  return (
    <>
      <h3 className={Classes.heading}>{t('sellerProfile.usps.title')}</h3>
      {uniqueSellingPoints.map((usp, index) => (
        <UniqueSellingPoint key={`usp-${index}`} text={usp} />
      ))}
    </>
  );
}
