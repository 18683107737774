import { ClientError } from '@ecg-marktplaats/aurora-node-api-client/dist/errors';
import { ERROR_REASON } from '../constants/constants';
import safeParse from './safeParse';

export default async function fetchAPI({ method = 'GET', url, xsrfToken, payload }) {
  const res = await fetch(url, {
    method,
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...(xsrfToken
        ? {
            'x-mp-xsrf': xsrfToken,
          }
        : {}),
    },
    ...(payload
      ? {
          body: JSON.stringify(payload),
        }
      : {}),
  });

  if (res.status >= 200 && res.status < 300) {
    return res.json();
  }
  if (res.status === 400) {
    const response = await res.text();
    const { details } = JSON.parse(response);
    const errData = details[0];

    throw new ClientError(errData.code, errData.message, errData);
  }
  if (res.status === 403) {
    const response = await res.text();
    const parsed = safeParse(response);

    if (
      parsed?.errorReason === ERROR_REASON.verificationRequired ||
      parsed?.errorReason === ERROR_REASON.verificationRequestsBlocked
    ) {
      window.location.assign(parsed.redirectUrl);
      return;
    }
  }

  throw new Error(res.statusText);
}
